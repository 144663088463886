.app {
  display: flex;
  height: 100vh;
  padding: 30px 8%;
  background-color: #5e5b5b;
}

@media screen and (max-width: 500px) {
  .app {
    padding: 30px 2%;
  }
}
