.tweet_form {
  margin: 40px;
  position: relative;
  display: flex;
}
.tweet_avatar {
  cursor: pointer;
  margin-top: 12px;
}
.tweet_input {
  margin-left: 40px;
  width: 90%;
  padding: 15px;
  border-radius: 30px;
  outline: none;
  border: none;
  color: #333333;
  font-size: 18px;
}
.icon_container {
  display: flex;
  justify-content: space-around;
}
.tweet_addIcon {
  cursor: pointer;
  color: white;
}
.tweet_addIconLoaded {
  cursor: pointer;
  color: dimgray;
}
.tweet_hiddenIcon {
  display: none;
}
.tweet_sendBtn {
  background-color: #00bfff !important;
  color: white !important;
  border-radius: 20px !important;
  margin: 12px !important;
}
.tweet_sendBtn :hover {
  opacity: 50%;
}
.tweet_sendDisableBtn {
  background-color: gray !important;
  color: white !important;
  border-radius: 30px !important;
  margin: 12px !important;
  cursor: not-allowed !important;
}

@media screen and (max-width: 500px) {
  .tweet_form {
    flex-flow: column;
    justify-content: center;
    margin: 0;
  }
  .tweet_avatar {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }
  .tweet_input {
    margin-left: 0;
    width: 100%;
  }
  .icon_container {
    display: flex;
    justify-content: space-around;
  }
}
