.login_toggleMode {
  cursor: pointer;
  color: #0000ff;
}
.app_title {
  text-align: center;
  margin-top: 84px !important;
  font-size: 40px !important;
  font-weight: bold !important;
}
.login_modal {
  text-align: center;
}
.login_reset {
  cursor: pointer;
}
.login_hiddenIcon {
  text-align: center;
  display: none;
}
.login_addIcon {
  cursor: pointer;
  color: gray;
}
.login_addIconLoaded {
  cursor: pointer;
  color: whitesmoke;
}
